<template>
  <div class="home">
    <login v-if="isNotExpired"/>
    <expired v-else />
  </div>
</template>

<script>
// @ is an alias to /src'
import Login from "../components/Login";
import Expired from "../components/Expired";
export default {
  name: 'Home',
  components: {
    Login,
    Expired
  },
  data() {
    return {
      isNotExpired: true
    }
  }
}
</script>
